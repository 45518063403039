import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import Auth from '@aws-amplify/auth';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ProfileService } from 'app/shared/services/profile.service';
import { environment } from "environments/environment";
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { NgxSpinnerModule } from "ngx-spinner";
import { AppComponent } from "./app.component";
import { rootRouterConfig } from "./app.routing";
import { IframeModule } from "./shared/iframe/iframe.module";
import { MaterialModule } from "./shared/modules/material.module";
import { MainProductServices } from "./shared/services/main.product.service";
import { SharedModule } from "./shared/shared.module";

export function HttpLoaderFactory(httpClient: HttpClient) {
	return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true
};


Auth.configure(environment.aws);

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		SharedModule,
		HttpClientModule,
		PerfectScrollbarModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		RouterModule.forRoot(rootRouterConfig, { useHash: true }),
		MaterialModule,
		NgxSpinnerModule,
		IframeModule
	],
	declarations: [
		AppComponent
	],
	entryComponents: [],
	providers: [
		MainProductServices,
		ProfileService,
		{ provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
