import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ProfileService {
	currentProfile = null;
	profile = new BehaviorSubject(this.currentProfile);

	constructor() {
		this.getCurrentProfile();
	}

	private getCurrentProfile() {
		const prefix = 'CognitoIdentityServiceProvider';
		const decodedCookie = decodeURIComponent(document.cookie);
		const cookies = decodedCookie.split(';');

		for (let cookie of cookies) {
			cookie = cookie.trim();

			if (cookie.startsWith(prefix)) {
				const c = cookie.split('=')[1]; // Obtén el valor de la cookie

				try {
					const obj = JSON.parse(c);
					if (obj?.UserAttributes) {
						const profile = obj.UserAttributes.find(attr => attr.Name === "profile");
						const value = profile?.Value;

						if (value) {
							this.currentProfile = value;
							this.profile.next(this.currentProfile);
							// this.products.next(environment.products.filter(x => x.type === "TSCTR"));
						}
					}
				} catch (_) {
					// console.error('Error parsing cookie', e);
				}
			}
		}
	}
}
