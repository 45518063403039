import { Injectable } from "@angular/core";
import { ProfileService } from 'app/shared/services/profile.service';
import { environment } from "environments/environment";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class MainProductServices {
	products = new BehaviorSubject<any>(environment.products);
	products$ = this.products.asObservable();

	constructor(profileService: ProfileService) {
		profileService.profile.subscribe(x => {
			if (x === "GRANDIA") {
				this.products.next(environment.products.filter(p => p.type === "TSCTR"));
			}
		});
	}


	getMainProduct(): string {
		return window.localStorage['typeToken'] ? window.localStorage['typeToken'] : 'SOAT';
	}

	saveMainProduct(token: string) {
		window.localStorage['typeToken'] = token;
	}
}
